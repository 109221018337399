import React from 'react'

import { BaseLayout } from 'templates'
import { PeopleListContainer } from 'containers'

import './People.module.scss'

const People = () => {
  return (
    <BaseLayout>
      <PeopleListContainer />
    </BaseLayout>
  )
}

export default People
