import React from 'react'

import { PersonCard } from 'components'
import { usePeople } from 'store'

import './PeopleListContainer.module.scss'

const PeopleListContainer = () => {
  const { getPeopleList } = usePeople()
  const peopleList = getPeopleList()
  const { loading, data, error } = peopleList

  if (loading) return <div>Carregando...</div>
  if (error) return <div>Erro ao carregar pessoas!</div>

  return (
    <main>
      {data.map(person => (
        <PersonCard person={person} />
      ))}
    </main>
  )
}

export default PeopleListContainer
