import { useMemo, useState, useEffect } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import peopleActions from './actions'
import peopleSelectors from './selectors'

const initialState = {
  listData: null,
  listLoading: false,
  listError: false,
}

const PeopleStore = useCreateStore(() => {
  const [$people, setPeople] = useState(initialState)
  const actions = useMemo(() => peopleActions(setPeople), [])
  const selectors = useMemo(() => peopleSelectors($people), [$people])

  useEffect(() => {
    const fetch = async () => {
      await actions.fetchPeopleList()
    }

    fetch()
  }, [actions])

  return { $people, ...actions, ...selectors }
})

export const usePeople = () => PeopleStore()
export const PeopleContext = PeopleStore.Context
export const PeopleProvider = PeopleStore.Provider
