import React from 'react'

import { BaseLayout } from 'templates'
import { CommonContainer } from 'containers'

import './Home.module.scss'

const Home = () => {
  return (
    <BaseLayout>
      <main>Content</main>
      <section>Section</section>
      <section>Section</section>
      <CommonContainer />
    </BaseLayout>
  )
}

export default Home
