import * as Joi from '@hapi/joi'

const dotEnvSchema = Joi.object().keys({
  NODE_ENV: Joi.string().required(),
  PUBLIC_URL: Joi.string().allow('', null),
  REACT_APP_API_URL: Joi.string().required(),
  REACT_APP_MOCK_API: Joi.string(),
})

const { error } = dotEnvSchema.validate(process.env)
if (error) throw new Error(`Please check your env file: ${error.message}`)
