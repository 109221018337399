import * as providers from './provider'

export default setPeople => {
  const fetchPeopleList = async () => {
    setPeople(prev => ({ ...prev, listLoading: true }))
    await setTimeout(async () => {
      const [err, res] = await providers.getPeople()

      if (err) {
        console.log('Erro ao buscar pessoas', err)
        setPeople(prev => ({ ...prev, listError: true, listLoading: false }))
        return
      }

      const people = res.data
      console.log('Sucesso ao buscar pessoas', res)
      setPeople(prev => ({ ...prev, listData: people, listLoading: false }))
    }, 1000)
  }

  return { fetchPeopleList }
}
