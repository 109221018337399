import React from 'react'
import PropTypes from 'prop-types'
import './PersonCard.module.scss'

const PersonCard = ({ person }) => {
  const { name, job, age, admission } = person
  return (
    <article>
      <h2>{name}</h2>
      <div>{job}</div>
      <div>{age}</div>
      <div>{admission}</div>
    </article>
  )
}

PersonCard.propTypes = {
  person: PropTypes.shape({
    name: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    admission: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
  }).isRequired,
}

export default React.memo(PersonCard)
