import React from 'react'
import { HomePage, PeoplePage } from 'pages'
import { Router } from '@reach/router'

export default function App() {
  return (
    <Router>
      <PeoplePage path="/" />
      <HomePage path="/example-route" />
    </Router>
  )
}
