import React from 'react'

import { MyComponent } from 'components'
import { useCommon } from 'store'

import './CommonContainer.module.scss'

const CommonContainer = () => {
  const { getNum, getSample, inc, testPost } = useCommon()
  const { num, teste } = getSample()

  const myPost = async () => {
    const res = await testPost()

    return res
  }

  return (
    <div>
      <h1>Container</h1>
      <MyComponent sampleNum={num || 0} sampleText={teste || 'Component'} />
      <div>{getNum()}</div>
      <button onClick={() => inc(1)} type="button">
        Change state
      </button>
      <button onClick={myPost} type="button">
        Make a network request
      </button>
    </div>
  )
}

export default CommonContainer
