export default $people => {
  const { listData, listError, listLoading } = $people

  const getPeopleList = () => ({
    data: listData || [],
    error: listError,
    loading: listLoading,
  })

  return { getPeopleList }
}
