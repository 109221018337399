import React from 'react'
import PropTypes from 'prop-types'

import { CommonProvider, PeopleProvider } from 'store'

const StateProviders = ({ children }) => (
  <PeopleProvider>
    <CommonProvider>{children}</CommonProvider>
  </PeopleProvider>
)

StateProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StateProviders
