import React from 'react'
import PropTypes from 'prop-types'

import './BaseLayout.module.scss'

const BaseLayout = ({ children }) => {
  return (
    <div>
      <header>nata.house</header>
      {children}
    </div>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BaseLayout
