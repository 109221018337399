import { useState, useEffect } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import commonActions from './actions'
import commonSelectors from './selectors'

// Store de exemplo, para mostrar como montaremos a arquitetura do sistema

const initialState = {
  num: 0,
  sample: {
    teste: '',
    num: 0,
  },
}

const CommonStore = useCreateStore(() => {
  const [$common, setCommon] = useState(initialState)
  const actions = commonActions(setCommon)
  const selectors = commonSelectors($common)

  useEffect(() => {
    const fetch = async () => {
      await actions.fetchSample()
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { $common, ...actions, ...selectors }
})

export const useCommon = () => CommonStore()
export const CommonContext = CommonStore.Context
export const CommonProvider = CommonStore.Provider
