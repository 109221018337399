import * as providers from './provider'

export default setCommon => {
  const inc = num => setCommon(prev => ({ ...prev, num: prev.num + num }))

  const testPost = async () => {
    const [res, err] = await providers.genericPost({})

    if (err) {
      console.log('Error posting')
    }
    console.log('Sucess posting')
    return res.data
  }

  const fetchSample = async () => {
    const [res, err] = await providers.getSample()

    if (err) {
      console.log('Erro ao buscar sample')
      return
    }

    const sample = res.data
    console.log('Sucesso ao buscar sample')
    setCommon(prev => ({ ...prev, sample }))
  }

  return { inc, testPost, fetchSample }
}
